import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../../components/seo"
import PersonCard from "../../components/person-card"
import Breadcrumbs from "../../components/breadcrumbs"
import { HomeLink, MeetDoctorLink, MeetTeamLink } from "../../components/links"

const AboutOrthodontistPage = ({ data }) => {
  const image = getImage(data.doctor)
  return (
    <>
      <SEO title="Meet our orthodontist" />

      <Breadcrumbs>
        <HomeLink />
        <MeetTeamLink />
        <MeetDoctorLink />
      </Breadcrumbs>

      <div className="flex flex-col lg:flex-row w-full space-x-4 lg:space-x-8">
        <PersonCard
          name="Dr. Huy D. Nguyen"
          image={
            <GatsbyImage
              image={image}
              alt="Portrait of Dr. Huy D. Nguyen"
              className="mask mask-circle"
            />
          }
          jobTitle="Certified Orthodontist"
          education="DMD, Cert. Ortho., Tufts University"
          languages="English, French, Vietnamese, Cantonese"
        ></PersonCard>

        <article className="prose dark:prose-invert prose-headings:text-brand-primary prose-headings:dark:text-brand-secondary">
          <h2>Meet Dr. Huy D. Nguyen</h2>
          <p>
            Dr. Huy Nguyen earned his Bachelor of Science (B.Sc.) from Western
            University and both his Doctor of Dental Medicine (D.M.D.) and
            Specialist Certificate in Orthodontics (Cert. Ortho.) from Tufts
            University School of Dental Medicine in Boston, Massachusetts. He
            served on the Tufts Dentistry Admissions Committee during his
            Orthodontics Residency from 1988-1991.
          </p>
          <p>
            Dr. Nguyen maintains full-time private practices in North York and
            Oshawa, Ontario. He speaks English, French, Vietnamese, and
            Cantonese Chinese.
          </p>
          <p>
            Dr. Nguyen maintains an active role in education; he has taught at
            Western University's Schulich School of Medicine and Dentistry since
            1999. He is currently an Adjunct Clinical Professor in the Schulich
            Department of Graduate Orthodontics.
          </p>
          <p>
            He is an active member of the Ontario Association of Orthodontists,
            the American Association of Orthodontists, and the Ontario Dental
            Association.
          </p>
          <h3>Dental School</h3>
          <p>
            Tufts University School of Dental Medicine, Boston, MA, United
            States
          </p>
          <h3>Orthodontics Residency</h3>
          <p>
            Tufts University School of Dental Medicine, Boston, MA, United
            States
          </p>
          <h3>Faculty Appointment</h3>
          <p>
            Adjunct Clinical Professor, Western University Schulich School of
            Medicine &amp; Dentistry, Department of Graduate Orthodontics,
            London, ON, Canada
          </p>
        </article>
      </div>
    </>
  )
}
export default AboutOrthodontistPage

export const query = graphql`
  query {
    doctor: file(relativePath: { eq: "team/dr-huy-nguyen.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
